import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { uniq, filter } from 'ramda';
import { NumericFormat } from 'react-number-format';
import PinIcon from '../../components/Icons/PinIcon';
import DownPinIcon from '../../components/Icons/DownPinIcon';
import TextInput from '../../components/TextInput';
import { useTargetsRequest, useUpdatePosMonthRequest } from "../../hooks/useApi";
import { creatTargetSalesFormData, deepMerge, targetSalesTotals, targetSalesAverage, parsePeriodsTargets, targetSalesTotalsV2, targetSalesAverageV2, monthlySum, monthlyAverage} from '../../utils/helpers';
import { FormatMoney, FormatNumberToOneDigit, FormatNumberToTwoDigits } from '../../utils/number-formats';
import debounce from 'lodash.debounce';
import './index.css';

const MONTH_MAP = { "1": "January","2": "February","3": "March","4": "April","5": "May","6": "June","7": "July","8": "August","9": "September","10": "Octomber","11": "November","12": "December" };
const CURRENCY_MAP = {"EUR": "€"};

const TargetTable = ({ targetData, selectedYear, handelChangePosId, getBodyParams }) => {
  const [formData, setFormData] = useState([]);
  const [ {}, updatePosMonth ] = useUpdatePosMonthRequest();
  const [ { loading }, getTotalsData ] = useTargetsRequest();
  const [stickTotal, setStickTotal] = useState(false);
  const [tTargetData, setTargetData] = useState(targetData);
  const rows = tTargetData?.elements;
  const monthsHeader = parsePeriodsTargets(rows[0]?.periodTarget);
  const posIdsToChange = useRef([]);
  const debouncedSave = useCallback(
    debounce(async(event, mergedObj) => {
      const { target: { value, name } } = event;
      const posIds = uniq(posIdsToChange.current);
      var promises = posIds.map(async item => {
        const posId = item.split('-')[0];
        const month = item.split('-')[1];
        const period = item.split('-')[2];
        const bodyParams = {
          year: selectedYear,
          month,
          period,
          posId,
          budget: mergedObj[posId][period].budget ?? 0,
          upt: mergedObj[posId][period].upt,
          averagePrice: mergedObj[posId][period].averagePrice,
          numberOfReceipt: mergedObj[posId][period].numberOfReceipt,
        };
        await updatePosMonth({ bodyParams });
      });
      Promise.all(promises).then(async()=> {
        posIdsToChange.current = [];
        const resp = await getTotalsData({ bodyParams: getBodyParams });
        setTargetData(resp?.data);
      });
      
    }, 1500),
    [], // will be created only once initially
  );
  
  const handleChange = async(event) => {
    const { target: { value, name } } = event;
    const posId = event.target.getAttribute('data-posid');
    const month = event.target.getAttribute('data-month');
    const period = event.target.getAttribute('data-period');
    const fieldName = event.target.getAttribute('data-fieldname');
    const newObj = {
      [posId]: {
        [period]: {
          [fieldName]: value || '0'
        }
      }
    };
    const mergedObj = deepMerge(formData, newObj);
    setFormData(mergedObj);
    debouncedSave.cancel();
    const newElement = `${posId}-${month}-${period}`;
    posIdsToChange.current.push(newElement);
    debouncedSave(event, mergedObj, posIdsToChange);
  };
  
  useEffect(() => {
    setFormData(creatTargetSalesFormData(rows));
  }, []);
  const totalsMonths = parsePeriodsTargets(rows[0]?.periodTarget);
  if (formData == [])
    return <>LOADING</>

  return (
    <div className='etroContainer targetSalesTableWrapper'>
      <table id="scrollableTable" className='tableBorderColor'>
        <thead>
          <tr className='bgTableGrey'>
            <th className='thinText sticky sticky1'>Action</th>
            <th className='thinText sticky sticky_2_1' colSpan="2">Store</th>
            <th colSpan="6" className={stickTotal ? 'divideLeft divideRight sticky sticky_3_6' : 'divideLeft divideRight'}>
              <span className="flex justify-between">
                <span>Total</span>
                <span className="pinIcon" onClick={()=>setStickTotal(!stickTotal)}>
                  {stickTotal && (<DownPinIcon />)}
                  {!stickTotal && (<PinIcon />)}
                </span>
              </span>
            </th>
            {
              Object.entries(monthsHeader).map((mh) => {
                const month = mh[0];
                return (
                  <th className='divideRight' colSpan="7">{MONTH_MAP[month]}</th>
                );              
              })
            }
          </tr>
          <tr className='bgTableGrey'>
            <th className="sticky sticky_row_2 sticky_row_2_col_1"><input type="checkbox" /></th>
            <th className='thinText sticky sticky_row_2_col_2 sticky_row_2'>Nation</th>
            <th className='thinText sticky sticky_row_2_col_3 sticky_row_2'> </th>
            <th className={stickTotal ? 'divideLeft sticky_row_2 sticky sticky_row_2_col_4' : 'divideLeft sticky_row_2'}>Budget</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_5' : 'sticky_row_2'}>UPT</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_6': 'sticky_row_2'}>Av. Price</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_7' : 'sticky_row_2'}>#Receipt</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_8' : 'sticky_row_2'}>VPT</th>
            <th className={stickTotal ? 'sticky_row_2 divideRight sticky sticky_row_2_col_9' : 'sticky_row_2 divideRight'}>Calculated<br/>Buget</th>
            {
              Object.entries(monthsHeader).map((mh)=>{
                return (
                  <>
                    <th className="sticky_row_2">Weeks</th>
                    <th className="sticky_row_2">Budget</th>
                    <th className="sticky_row_2">UPT</th>
                    <th className="sticky_row_2">Av. Price</th>
                    <th className="sticky_row_2">#Receipt</th>
                    <th className="sticky_row_2">VPT</th>
                    <th className='divideRight sticky_row_2'>Calculated Buget</th>
                  </>
                );              
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map( (row) => {
              const months = parsePeriodsTargets(row?.periodTarget);
              return (
                <>
                  <tr key={row.posId}>
                    <td rowSpan={7} class="sticky sticky1 align-top"><input type="checkbox" value={row.posId} onChange={handelChangePosId} /></td>
                    <td rowSpan={7} className='thinText sticky sticky2 align-top'>{row.countryDs}</td>
                    <td rowSpan={7} className='thinText sticky sticky3 align-top'>{row.posDs}</td>
                    <td rowSpan={7} className={stickTotal ? 'divideLeft textRight sticky sticky4 align-top' : 'divideLeft textRight align-top'}>
                      <div>
                        {<NumericFormat value={row.total?.budget?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={0} />}
                      </div>
                      <div className='tdSubBudget'>
                        {<NumericFormat value={row.total?.planned?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.planned?.currency]}`} displayType="text" decimalScale={0} />}
                      </div>
                    </td>
                    <td rowSpan={7} className={stickTotal ? 'textRight sticky sticky5 align-top' : 'textRight align-top'}>{<NumericFormat value={row.total?.upt} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td rowSpan={7} className={stickTotal ? 'textRight sticky sticky6 align-top' : 'textRight align-top'}>{<NumericFormat value={row.total?.averagePrice} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
                    <td rowSpan={7} className={stickTotal ? 'textRight sticky sticky7 align-top' : 'textRight align-top'}>{row.total?.numberOfReceipt}</td>
                    <td rowSpan={7} className={stickTotal ? 'textRight sticky sticky8 align-top' : 'textRight align-top'}>{<NumericFormat value={row.total?.vpt} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td rowSpan={7} className={stickTotal ? 'divideRight textRight sticky sticky9 align-top' : 'divideRight textRight align-top'}>{<NumericFormat value={row.total?.budgetCalculated} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
                  </tr>
                  <tr>
                  {
                    Object.entries(months).map((mth) => {
                      const monthNumber = mth[0];
                      return (
                        <>
                          <td style={{"z-index": 0}}>Total</td>
                          <td className="textRight">
                            <div>
                              {<NumericFormat value={monthlySum(row.periodTarget, monthNumber, 'budget.value')} suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} thousandSeparator=" " displayType="text" decimalScale={0} />}
                            </div>
                            <div className='tdSubBudget'>
                              {<NumericFormat value={monthlySum(row.periodTarget, monthNumber, 'planned.value')} suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} thousandSeparator=" " displayType="text" decimalScale={0} />}
                            </div>
                          </td>
                          <td className="textRight">{<NumericFormat value={monthlyAverage(row.periodTarget, monthNumber, 'upt')} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                          <td className="textRight">{<NumericFormat value={monthlyAverage(row.periodTarget, monthNumber, 'averagePrice')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
                          <td className="textRight">{monthlySum(row.periodTarget, monthNumber, 'numberOfReceipt')}</td>
                          <td className="textRight">{<NumericFormat value={monthlyAverage(row.periodTarget, monthNumber, `vpt`)} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                          <td className="textRight">{<NumericFormat value={monthlySum(row.periodTarget, monthNumber, `budgetCalculated`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
                        </>
                      );
                    })
                  }
                  </tr>
                  {
                    [1, 2, 3, 4, 5].map((weekNumber) => {
                      return (<tr>
                        {
                          Object.entries(months).map((mth) => {
                            const monthNumber = mth[0];
                            const weeks = mth[1];
                            const week = weeks[weekNumber-1];
                            if (!week)
                              return (<><td>Week {weekNumber}</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></>);
                            const { month, budget, upt, averagePrice, numberOfReceipt, planned, vpt, budgetCalculated, isEditable, period } = week;
                            return (
                              <>
                                <td className="weekTd">Week {weekNumber}</td>
                                <td className='textRight'>
                                  <div>
                                    {isEditable ? (
                                      <TextInput
                                        disabled={loading}
                                        posId={row.posId}
                                        month={month}
                                        period={period}
                                        fieldName="budget"
                                        value={formData?.[row.posId]?.[period]?.budget || budget?.value}
                                        // defaultValue={formData?.[row.posId]?.[month]?.budget || budget?.value}
                                        onChange={handleChange}
                                        addAdornment={true}
                                      />
                                    ) : (
                                      <div style={{minWidth: '104px'}}><FormatMoney value={budget?.value} /></div>
                                    )}
                                    <div className='plannedBudget'>
                                      Planned:&nbsp;
                                      <Link
                                        className={planned?.value == budget?.value ? 'textGreen' : 'textYellow'}
                                        onClick={() => {
                                          localStorage.setItem('posId', row?.posId);
                                          localStorage.setItem('selectedYear', selectedYear);
                                          localStorage.setItem('month', month);
                                          localStorage.setItem('period', period);
                                          localStorage.setItem('weekNumber', weekNumber);
                                          localStorage.setItem('backUrl', window.location.href);
                                          window.location.replace('/planned-sales');
                                        }}
                                      >
                                        <NumericFormat value={planned?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[planned?.currency]}`} displayType="text" decimalScale={0} />
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td className='textRight'>
                                  { isEditable ? (
                                    <TextInput
                                      disabled={loading}
                                      posId={row.posId}
                                      month={month}
                                      period={period}
                                      fieldName="upt"
                                      value={formData?.[row.posId]?.[period]?.upt || upt}
                                      // defaultValue={formData?.[row.posId]?.[month]?.upt || upt}
                                      onChange={handleChange}
                                    />
                                  ) : (
                                    <FormatNumberToOneDigit value={upt} />
                                  )}
                                </td>
                                <td className='textRight'>
                                  { isEditable ? (
                                    <TextInput
                                      disabled={loading}
                                      posId={row.posId}
                                      month={month}
                                      period={period}
                                      fieldName="averagePrice"
                                      value={formData?.[row.posId]?.[period]?.averagePrice || averagePrice}
                                      // defaultValue={formData?.[row.posId]?.[month]?.averagePrice || averagePrice}
                                      onChange={handleChange}
                                      addAdornment={true}
                                    />
                                  ) : (
                                    <FormatMoney value={averagePrice} />
                                  )
      
                                  }
                                </td> 
                                <td className='textRight'>
                                  { isEditable ? (
                                    <TextInput
                                      disabled={loading}
                                      posId={row.posId}
                                      month={month}
                                      period={period}
                                      fieldName="numberOfReceipt"
                                      value={formData?.[row.posId]?.[period]?.numberOfReceipt || numberOfReceipt}
                                      // defaultValue={formData?.[row.posId]?.[month]?.numberOfReceipt || numberOfReceipt}
                                      onChange={handleChange}
                                    />
                                  ) : (
                                    numberOfReceipt
                                  )}
                                </td>
                                <td className='textRight'><NumericFormat value={vpt} thousandSeparator=" " displayType="text" decimalScale={2} /></td>
                                <td className='divideRight textRight'><NumericFormat value={budgetCalculated} thousandSeparator=" " suffix={` €`} displayType="text" decimalScale={0} /></td>
                              </>
                            );
                          })
                        }
                      </tr>);
                    })
                  }
                </>
              );
            }

            )
          }
          <tr className="bgTableGrey totalRow">
            <td colSpan={3} className="sticky">Total</td>
            <td className={stickTotal ? 'textRight sticky sticky4' : 'textRight'}>
              <div>{<NumericFormat value={targetSalesTotals(rows, 'total.budget.value')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
              <div className="tdSubBudget">{<NumericFormat value={targetSalesTotals(rows, 'total.planned.value')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
            </td>
            <td className={stickTotal ? 'textRight sticky sticky5' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.upt')} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
            <td className={stickTotal ? 'textRight sticky sticky6' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.averagePrice')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
            <td className={stickTotal ? 'textRight sticky sticky7' : 'textRight'}>{targetSalesTotals(rows, 'total.numberOfReceipt')}</td>
            <td className={stickTotal ? 'textRight sticky sticky8' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.vpt')} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
            <td className={stickTotal ? 'divideRight textRight sticky sticky9' : 'divideRight textRight'}>{<NumericFormat value={targetSalesTotals(rows, 'total.budgetCalculated')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
            {
              Object.entries(totalsMonths).map((month) => {
                const [monthNumber, monthData] = month;
                return (
                  <>
                    <td></td>
                    <td className="textRight">
                      <div>{<NumericFormat value={targetSalesTotalsV2(rows, monthNumber, `budget.value`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
                      <div className="tdSubBudget">{<NumericFormat value={targetSalesTotalsV2(rows, monthNumber, `planned.value`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
                    </td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverageV2(rows, monthNumber, `upt`)} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverageV2(rows, monthNumber, `averagePrice`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
                    <td className="textRight">{targetSalesTotalsV2(rows, monthNumber, `numberOfReceipt`)}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverageV2(rows, monthNumber, `vpt`)} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesTotalsV2(rows, monthNumber, `budgetCalculated`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[1][0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
                  </>
                );
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TargetTable;
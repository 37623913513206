import { useFilterData } from "../../hooks/useFilterData";
import { useTargetsRequest, useDownloadPosYearTargetExcelRequest } from "../../hooks/useApi";
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TargetTable from './TargetTable';
import GridTable from './GridTable';
import SearchBtn from '../../components/SearchBtn';
import OutlinedBtn from '../../components/OutlinedBtn';
import { CopyBudget } from '../../components/CopyBudget/index';
import * as React from 'react';
import AlertIcon from '../../components/Icons/AlertIcon';
import SwapIcon from '../../components/Icons/SwapIcon';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import Pagination from '@mui/material/Pagination';
import { Stack } from "@mui/material";
import { downloadFile } from '../../utils/helpers';
import LoadingOverlay from '../../components/LoadingOverlay';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get("year");
  const countriesStr = searchParams.get("countries") || '';
  const pageSizeStr = searchParams.get("pageSize") || 10;
  const pageIndexStr = searchParams.get("pageIndex") || 1;
  const { countries, executeSearchCountry, years } = useFilterData();
  const [ { loading }, executeRequest ] = useTargetsRequest();
  const [ { loading: loadingFile}, downloadExcel ] = useDownloadPosYearTargetExcelRequest();
  const [selectedCountries, setCountry] = useState(countriesStr ? countriesStr.split(',') : []);
  const [selectedYear, setYear] = useState(year);
  const [targetData, setTargetData] = useState(null);
  const [pageSize, setPageSize] = useState(pageSizeStr);
  const [pageIndex, setPageIndex] = useState(pageIndexStr);
  const [selectedPosIds, setPosId] = useState({});
  const [showCopyPrevYear, setShowCopyPrevYear] = useState(false);

  const FetchTargetSales = useCallback((setAllData) => {
    const aaa = async () => {
      if (selectedCountries && selectedCountries != [''] && selectedYear)
      {
        const bodyParams = {
          countryIds: selectedCountries,
          year: selectedYear,
          pageSize: pageSize,
          pageIndex: pageIndex
        };

        const response = await executeRequest({ bodyParams });
        setTargetData(response?.data);
      }
    };
    aaa();
  }, [selectedCountries, selectedYear, pageSize, pageIndex]);

  useEffect(() => {
    FetchTargetSales();
  }, [pageSize, pageIndex]);

  if (!countries || !years || loading || loadingFile)
    return <LoadingOverlay />
  
  const handleChange = (event) => {
    const { target: { value } } = event;
    setCountry(
      typeof value === 'string' ? value.split(',') : value,
    );
    setSearchParams({ countries: value.join(','), year: selectedYear, pageSize: pageSize, pageIndex: 1 });
    setPageIndex(1);
  };
  const handleChangeYear = (event) => {
    const { target: { value } } = event;
    setSearchParams({ countries: selectedCountries, year: value, pageSize: pageSize, pageIndex: 1 });
    setYear(value);
    setPageIndex(1);
  };
  const handleChangePageSzie = (event) => {
    const { target: { value } } = event;
    setSearchParams({ countries: selectedCountries, year: value, pageSize: value, pageIndex: 1 });
    setPageSize(value);
    setPageIndex(1);
  };

  const handleChangePageIndex = (event, value) => {
    setSearchParams({ countries: selectedCountries, year: value, pageSize: value, pageIndex: value });
    setPageIndex(value);
  };

  const downloadExcelHandleClick = async (event) => {
    const bodyParams = {
      pageIndex,
      pageSize,
      countryIds: selectedCountries,
      year: selectedYear
    };
    const fileName = `StoreBudget-${selectedYear}.xlsx`;
    downloadFile(bodyParams, fileName, downloadExcel);
    await FetchTargetSales();
  }

  const handelChangePosId = async (event) => {
    const { target: { value, checked } } = event;
    const obj = Object.assign({}, selectedPosIds);
    if (checked == false)
    {
      delete obj[value];
      setPosId(obj);
    }
    else
      setPosId((prevCheckStates) => {
        return { ...prevCheckStates, [value]: true }
      });
  }

  return (
    <>
      <div className="formWrapper etroContainer">
        <FormControl sx={{ m: 1, width: 150 }}>
          <InputLabel id="countries-label">Countries</InputLabel>
          <Select
              labelId="countries-label"
              id="countries"
              multiple
              value={selectedCountries}
              onChange={handleChange}
              input={<OutlinedInput label="Countries" />}
              renderValue={(selected) => selected.join(', ')}
              size="small"
              sx={{
                p: 0,
              }}
            >
            {JSON.parse(countries).map(({countryId, countryDs}) => (
              <MenuItem key={countryId} value={countryId}>
                <Checkbox checked={selectedCountries.includes(countryId)} />
                <ListItemText primary={countryDs} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="years-label">Year</InputLabel>
          <Select
            labelId="years-label"
            id="years"
            value={selectedYear}
            onChange={handleChangeYear}
            autoWidth
            label="Years"
            size="small"
            sx={{
              p: 0,
            }}
          >
            {JSON.parse(years).map((year) => (
              <MenuItem key={year} value={year}>
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchBtn onClick={FetchTargetSales}>Search</SearchBtn>
      </div>
      <div className="etroContainer tableOptions">
        <div className="" style={{display: 'flex', justifyContent: 'start', position: 'relative'}}>
          <div className="paginationTitle">Overall Budget ({targetData ? targetData?.totalResults : 0})</div>
          <div className="flex flex-center">
            <OutlinedBtn disabled={Object.keys(selectedPosIds).length <= 0} title="Copy budget from previous year" onClick={(event) => {setShowCopyPrevYear(!showCopyPrevYear)}} variant="outlined" startIcon={<SwapIcon />} />
            {showCopyPrevYear && (<CopyBudget targetData={targetData} selectedPosIds={selectedPosIds} selectedYear={selectedYear} handlCancelClick={setShowCopyPrevYear} afterSaveHandle={FetchTargetSales} />)}
          </div>
        </div>
        <OutlinedBtn disabled={!targetData} title="Download EXCEL" variant="outlined" onClick={downloadExcelHandleClick} startIcon={<DownloadIcon />} />
      </div>
      {targetData ? 
      (
        <div>
          <TargetTable
            targetData={targetData}
            selectedYear={selectedYear}
            fetchTargetSales={FetchTargetSales}
            handelChangePosId={handelChangePosId}
            getBodyParams = {{
              countryIds: selectedCountries,
              year: selectedYear,
              pageSize: pageSize,
              pageIndex: pageIndex
            }}
          />
          
          <div className="etroContainer" style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "start", alignItems: "center" }}>
              <div>Rows per page</div>
              <FormControl sx={{ m: 1, width: 80 }}>
                <Select
                  id="pageSize"
                  value={pageSize}
                  onChange={handleChangePageSzie}
                  size="small"
                >
                  {[10, 20, 50].map((pageSizeOption) => (
                    <MenuItem key={`pageSize-${pageSizeOption}`} value={pageSizeOption}>
                      <ListItemText primary={pageSizeOption} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Pagination page={pageIndex} count={targetData?.totalPages} onChange={handleChangePageIndex} size="large" />
          </div>
        </div>
      ) : (
        <div className="noResultsWrapper etroContainerM">
          <AlertIcon />
          <div className="errorTitle">Overall Target Missing</div>
          <div className="errorSubtext">Select nation and year to search</div>
        </div>
      )}
    </>  
  );
};

export default Dashboard;

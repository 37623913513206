import { useEffect, useState } from "react";
import { compose, count, map, sum, mean, filter, propEq, propOr, prop, is, mergeWith } from 'ramda';
import { dotPath, dotPathOr  } from 'ramda-extension';

export const createIsActiveMap = compose(
  (params) => {
    let dt = {};
    params.map(({ dayNumber, isOpen}) => {
      dt = { ...dt, [`${dayNumber}`]: isOpen }
    });
    
    return dt; 
  },
  dotPathOr({}, 'data.posDays')
);

export const createPlannedMap = compose(
  (params) => {
    let dt = {};
    params.map(({ dayNumber, planned}) => {
      dt = { ...dt, [`${dayNumber}`]: planned }
    });
    
    return dt; 
  },
  dotPathOr({}, 'data.posDays')
);

export const sumPlannedDays = compose(
  sum,
  map(prop('planned')),
  filter((prop) => prop.isOpen),
  dotPathOr([], 'posDays')
);

export const monthlySum = (allRows, monthNumber, path) => compose(
  sum,
  map(dotPathOr([], path)),
  filter((prop) => prop.month==monthNumber),
)(allRows);

export const monthlyAverage = (allRows, monthNumber, path) => compose(
  mean,
  map(dotPathOr([], path)),
  filter((prop) => prop.month==monthNumber),
)(allRows);

export const countOpenDays = compose(
  count((prop) => prop.isOpen),
  dotPathOr([], 'posDays')
);

export const creatTurnoverState = (data) => {
  if (!data)
    return {};
  let obj = {};
  data.map((dt) => {
    obj = { ...obj, [`${dt.userId}`]: {
      averagePrice: dotPathOr('0', `averagePrice.planned`, dt),
      upt: dotPathOr('0', `upt.planned`, dt),
      numberOfReceipt: dotPathOr('0', `numberOfReceipt.planned`, dt),
      workingDays: dotPathOr('0', `workingDays`, dt),
    } }
  });

  return obj;
};

export const targetSalesTotals = (rows, path) => compose(
  sum,
  map(dotPath(path))
)(rows);

export const targetSalesTotalsV2 = (rows, monthNumber, path) => compose(
  sum,
  map(
    compose(
      sum,
      map((prop) => { return dotPath(path, prop);}),  
      filter((prop) => prop.month == monthNumber),
    ),
  ),
  map(dotPath('periodTarget'))
)(rows);

export const targetSalesAverage = (rows, path) => compose(
  mean,
  map(dotPath(path))
)(rows);

export const targetSalesAverageV2 = (rows, monthNumber, path) => compose(
  mean,
  map(
    compose(
      mean,
      map((prop) => { return dotPath(path, prop);}),  
      filter((prop) => prop.month == monthNumber),
    ),
  ),
  map(dotPath('periodTarget'))
)(rows);

export const targetSalesRatio = (rows, path) => {
  const planned = targetSalesTotals(rows, `${path}.planned`);
  const actual = targetSalesTotals(rows, `${path}.actual`);

  if (planned == 0)
    return 0;
  return (actual/planned) * 100;
};

export const targetSalesRatioByAverage = (rows, path) => {
  const planned = targetSalesAverage(rows, `${path}.planned`);
  const actual = targetSalesAverage(rows, `${path}.actual`);

  if (planned == 0)
    return 0;
  return (actual/planned) * 100;
};

export const creatTargetSalesFormData = (data) => {
  if (!data)
    return {};
  let obj = {};
  data.map((dt) => {
    const periodTarget = dt?.periodTarget;
    periodTarget.map((pt) => {
      obj = deepMerge(obj, {[`${dt.posId}`]:
        {
          [`${pt.period}`]:
          {
            budget: pt?.budget?.value,
            upt: pt?.upt,
            averagePrice: pt?.averagePrice,
            numberOfReceipt: pt?.numberOfReceipt,
          }
        }
        });
    });
  });

  return obj;
};

export const deepMerge = (a, b) => {
  return (is(Object, a) && is(Object, b)) ? mergeWith(deepMerge, a, b) : b;
}

export const downloadFile = async (bodyParams, fileName, downloadFn) => {
  
  const response = await downloadFn({ bodyParams });
  const blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(url);
}

export function debounce(callback, wait) {
  let timeout;

  return function(...args) {
    const later = () => {
      timeout = null;
      callback(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const parsePeriodsTargets = (periodTarget) => Object.groupBy(periodTarget, ({ month }) => month)

export const GetPosPeriods = (rows, posId) => compose(
  dotPath('periodTarget'),
  prop(0),
  filter((r) => r.posId == posId)
)(rows);
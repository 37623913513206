import { useGetRequest, usePostRequest } from "./useRequest";

export const useWhoAmIQueryRequest = (params) =>
  useGetRequest("/User/WhoAmI", {}, null, null, true);

export const useCountryListRequest = () =>
  useGetRequest("/User/GetMyCountries", {}, null, null, true);

export const useYearsListRequest = () =>
  useGetRequest("/Target/GetMyTargetYears", {}, null, null, true);

export const useTargetsRequest = (params = {}) =>
  usePostRequest("/Target/GetPosYearTargets", params, null, null, true);

export const usePosDayPlannedRequest = (params = {}) =>
  usePostRequest("/Target/GetPosDayPlanned", params, null, null, true);

export const usePosMonthRequest = (params = {}) =>
  usePostRequest("/Target/GetPosMonthPeriod", params, null, null, true);

export const useUpdatePosMonthRequest = (params = {}) =>
  usePostRequest("/Target/UpdatePosPeriod", params, null, null, true);

export const usePosUsersRequest = (params = {}) =>
  usePostRequest("/Target/GetPosUsers", params, null, null, true);

export const useUpdateIsOpenPosDayRequest = (params = {}) =>
  usePostRequest("/Target/UpdateIsOpenPosDay", params, null, null, true);

export const useUpdatePlannedPosDayRequest = (params = {}) =>
  usePostRequest("/Target/UpdatePlannedPosDay", params, null, null, true);

export const useUpdatePlannedPosDaysRequest = (params = {}) =>
  usePostRequest("/Target/UpdatePlannedPosDays", params, null, null, true);

export const useUpdatePosUserRequest = (params = {}) =>
  usePostRequest("/Target/UpdatePosUser", params, null, null, true);

export const useDownloadPosUserTargetExcelRequest = (params = {}) =>
  usePostRequest("/Target/DownloadPosUserTargetExcel", params, null, null, true, false, 'blob');

export const useDownloadPosYearTargetExcelRequest = (params = {}) =>
  usePostRequest("/Target/DownloadPosYearTargetExcel", params, null, null, true, false, 'blob');

export const useCopyPlannedFromPreviousYearRequest = (params = {}) =>
  usePostRequest("/Target/CopyPlannedFromPreviusYear", params, null, null, true, false, 'blob');

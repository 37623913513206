import { useState } from "react";
import OutlinedBlackBtn from '../OutlinedBlackBtn';
import FilledBlackButton from '../FilledBlackButton';
import TextInput from '../TextInput';
import { useCopyPlannedFromPreviousYearRequest } from "../../hooks/useApi";
import { GetPosPeriods } from '../../utils/helpers';

export const CopyBudget = ({ handlCancelClick, selectedPosIds, selectedYear, afterSaveHandle, targetData }) => {
  const rows = targetData?.elements;
  const [ s1, increaseFromPrevYear ] = useCopyPlannedFromPreviousYearRequest();
  const [percent, setPercent] = useState(null);
  const handleChange = (event) => {
    setPercent(event.target.value.replace(' %', ''));
  };

  const handleOnSaveClick = async (event) => {
    const pointOfSaleMonths = [];
    Object.entries(selectedPosIds).map((aa) => {
      const periods = GetPosPeriods(rows, aa[0]);
      periods.map((period) => {
        pointOfSaleMonths.push({
          year: selectedYear,
          month: period.month,
          period: period.period,
          posId: aa[0]
        });
      })
    });

    const bodyParams = {
      pointOfSalePeriod: pointOfSaleMonths,
      increaseBy: percent
    };
    handlCancelClick(false);
    await increaseFromPrevYear({ bodyParams });
    await afterSaveHandle();
  };

  return (
  <div className="customDropdown">
    <div className="inputTitle">Copy budget from previous year with edits:</div>
    <div className="inputLabel">Increased by:</div>
    <div className="">
      <TextInput
        value={percent}
        onChange={handleChange}
        className="short"
        addAdornment={true}
        adornmentPosition="right"
        addAdornmentText="%"
      />
    </div>
    <div className="prevYearBtnsFooter">
      <OutlinedBlackBtn title="Cancel" variant="outlined" onClick={(e) => {handlCancelClick(false);}} />
      <FilledBlackButton title="Save" variant="outlined" onClick={handleOnSaveClick} />
    </div>
  </div>
)};

const TextInput = ({ posId, period, month, fieldName, value, onChange, onBlur, onKeyDown, name, addAdornment = false, adornmentPosition = 'left', addAdornmentText = '€', dataProp, defaultValue, ref, disabled = false }) => {
  return (
    <div className={`formControl ${adornmentPosition}`}>
      <input
        disabled={disabled}
        type="text"
        data-posid={posId}
        data-month={month}
        data-period={period}
        data-fieldname={fieldName}
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        name={name}
        data-prop={dataProp}
      />
      {addAdornment && (<div className={`inputAdornment ${adornmentPosition}`}>{addAdornmentText}</div>)}
    </div>
  );
}

export default TextInput;